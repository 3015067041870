<template>
    <div class="lvya-schoolBigData">
        <div class="schoolBigData-div1">
        <img src="static/lvya/basicPlatform/schoolBigData/xydsjbanner.png" alt="">
            <ul class="schoolBigData-ul">
                <li>
                    <img src="static/lvya/basicPlatform/schoolBigData/czfs.png" alt="">
                    <p class="schoolBigData-ul-p1">操作方式</p>
                    <p class="schoolBigData-ul-p2">可视化交互式、简单化、快捷化</p>
                </li>
                <li>
                    <img src="static/lvya/basicPlatform/schoolBigData/xxpj.png" alt="">
                    <p class="schoolBigData-ul-p1">学校评价</p>
                    <p class="schoolBigData-ul-p2">精准性、科学性、客观性</p>
                </li>
                <li>
                    <img src="static/lvya/basicPlatform/schoolBigData/xxgl.png" alt="">
                    <p class="schoolBigData-ul-p1">学校管理</p>
                    <p class="schoolBigData-ul-p2">透明化、简约化、信息化</p>
                </li>
            </ul>
        </div>
        <div class="schoolBigData-div2">
            <ul class="tab-tilte">
                <li @click="cur=0" :class="{active:cur==0}" @mouseover="p1 = pic11,change(0)" @mouseout="p1 = pic1,change2('00')">
                    <!-- 默认显示 -->
                    <img v-if="cur==0 && cur2!='00'" :src="p1" class="schoolBigDataIco">
                    <!-- 离开后显示 -->
                    <img v-if="cur==0 && cur2=='00'" :src="pic11" class="schoolBigDataIco">
                    <!-- 滑到其他列表的时候显示 -->
                    <img v-if="cur!=0 && cur2!='00'" :src="pic1" class="schoolBigDataIco">
                    <img v-if="cur!=0 && cur2=='00'" :src="pic11" class="schoolBigDataIco">
                    <span>强大的数据统计功能</span>
                </li>
                <li @click="cur=1" :class="{active:cur==1}" @mouseover="p2 = pic22,change(1)" @mouseout="p2 = pic2,change2('11')">
                    <!-- 默认显示 -->
                    <img v-if="cur==1 && cur2!='11'" :src="p2" class="schoolBigDataIco">
                    <!-- 离开后显示 -->
                    <img v-if="cur==1 && cur2=='11'" :src="pic22" class="schoolBigDataIco">
                    <!-- 滑到其他列表的时候显示 -->
                    <img v-if="cur!=1 && cur2!='11'" :src="pic2" class="schoolBigDataIco">
                    <img v-if="cur!=1 && cur2=='11'" :src="pic22" class="schoolBigDataIco">
                    <span>实时生成多种数据透视表</span>
                </li>
                <li @click="cur=2" :class="{active:cur==2}" @mouseover="p3 = pic33,change(2)" @mouseout="p3 = pic3,change2('22')">
                    <!-- 默认显示 -->
                    <img v-if="cur==2 && cur2!='22'" :src="p3" class="schoolBigDataIco">
                    <!-- 离开后显示 -->
                    <img v-if="cur==2 && cur2=='22'" :src="pic33" class="schoolBigDataIco">
                    <!-- 滑到其他列表的时候显示 -->
                    <img v-if="cur!=2 && cur2!='22'" :src="pic3" class="schoolBigDataIco">
                    <img v-if="cur!=2 && cur2=='22'" :src="pic33" class="schoolBigDataIco">
                    <span>自定义功能排版</span>
                </li>
                <li @click="cur=3" :class="{active:cur==3}" @mouseover="p4 = pic44,change(3)" @mouseout="p4 = pic4,change2('33')">
                    <!-- 默认显示 -->
                    <img v-if="cur==3 && cur2!='33'" :src="p4" class="schoolBigDataIco">
                    <!-- 离开后显示 -->
                    <img v-if="cur==3 && cur2=='33'" :src="pic44" class="schoolBigDataIco">
                    <!-- 滑到其他列表的时候显示 -->
                    <img v-if="cur!=3 && cur2!='33'" :src="pic4" class="schoolBigDataIco">
                    <img v-if="cur!=3 && cur2=='33'" :src="pic44" class="schoolBigDataIco">
                    <span>可视化数据管理</span>
                </li>
            </ul>
            <div class="tab-content">
                <div v-show="cur==0">
                    <img src="static/lvya/basicPlatform/schoolBigData/schoolBigData1.png" alt="" width="100%">
                </div>
                <div v-show="cur==1">
                    <img src="static/lvya/basicPlatform/schoolBigData/schoolBigData2.png" alt="" width="100%">
                </div>
                <div v-show="cur==2">
                    <img src="static/lvya/basicPlatform/schoolBigData/schoolBigData3.png" alt="" width="100%">
                </div>
                <div v-show="cur==3">
                    <img src="static/lvya/basicPlatform/schoolBigData/schoolBigData4.png" alt="" width="100%">
                </div>
            </div>
        </div>
        <div  class="schoolBigData-div3-bg">
            <div class="schoolBigData-div3">
                <video-player  class="video-player vjs-custom-skin"
                    ref="videoPlayer"
                    :playsinline="true"
                    :options="playerOptions"
                ></video-player>
            </div>
        </div>
        <div class="schoolBigData-bg">
            <div class="schoolBigData-div4">
                <p class="administration-tit">校园三元素</p>
                <p class="businessCenter-box1-text">
                    校园核心三元素优化重构，激发学校自身活力
                </p>
                <div class="tab2">
                    <div class="tab-content">
                        <div v-show="cur3==0" style="box-shadow: 1px 2px 9px #ccc;">
                            <img src="static/lvya/basicPlatform/schoolBigData/xshx.png" alt="" width="100%;">
                        </div>
                        <div v-show="cur3==1" style="box-shadow: 1px 2px 9px #ccc;">
                            <img src="static/lvya/basicPlatform/schoolBigData/jshx.png" alt="" width="100%">
                        </div>
                        <div v-show="cur3==2" style="box-shadow: 1px 2px 9px #ccc;">
                            <img src="static/lvya/basicPlatform/schoolBigData/kchx.png" alt="" width="100%">
                        </div>
                    </div>
                    <ul class="tab-tilte">
                        <li @click="cur3=0" :class="{active:cur3==0}" @mouseover="x1 = xs11,change3(0)" @mouseout="x1 = xs1,change4('00')">
                            <div>
                                <img v-if="cur3==0 && cur4!='00'" :src="x1" class="schoolBigDataIco2">
                                <!-- 离开后显示 -->
                                <img v-if="cur3==0 && cur4=='00'" :src="xs11" class="schoolBigDataIco2">
                                <!-- 滑到其他列表的时候显示 -->
                                <img v-if="cur3!=0 && cur4!='00'" :src="xs1" class="schoolBigDataIco2">
                                <img v-if="cur3!=0 && cur4=='00'" :src="xs11" class="schoolBigDataIco2">
                            </div>
                            <div>
                                <p class="tab2-p1">学生画像</p>
                                <p class="tab2-p2">绘制学生立体成绩单，全面分析学生综合能力成长过程。</p>
                            </div>
                        </li>
                        <div style="margin-left: 35px;">
                            <img src="static/lvya/basicPlatform/schoolBigData/line2.png" alt="">
                        </div>
                        <li @click="cur3=1" :class="{active:cur3==1}" @mouseover="j1 = js11,change3(1)" @mouseout="j1 = js1,change4('11')">
                            <div>
                                <img v-if="cur3==1 && cur4!='11'" :src="j1" class="schoolBigDataIco2">
                                <img v-if="cur3==1 && cur4=='11'" :src="js11" class="schoolBigDataIco2">
                                <img v-if="cur3!=1 && cur4!='11'" :src="js1" class="schoolBigDataIco2">
                                <img v-if="cur3!=1 && cur4=='11'" :src="js11" class="schoolBigDataIco2">
                            </div>
                            <div>
                                <p class="tab2-p1">教师画像</p>
                                <p class="tab2-p2">分析挖掘教师能力发展变化，完成教师能力画像大数据，重新激活育人能力。</p>
                            </div>
                        </li>
                        <div style="margin-left: 35px;">
                            <img src="static/lvya/basicPlatform/schoolBigData/line2.png" alt="">
                        </div>
                        <li @click="cur3=2" :class="{active:cur3==2}" @mouseover="k1 = kc11,change3(2)" @mouseout="k1 = kc1,change4('22')">
                            <div>
                                <img v-if="cur3==2 && cur4!='22'" :src="k1" class="schoolBigDataIco2">
                                <img v-if="cur3==2 && cur4=='22'" :src="kc11" class="schoolBigDataIco2">
                                <img v-if="cur3!=2 && cur4!='22'" :src="kc1" class="schoolBigDataIco2">
                                <img v-if="cur3!=2 && cur4=='22'" :src="kc11" class="schoolBigDataIco2">
                            </div>
                            <div>
                                <p class="tab2-p1">课程画像</p>
                                <p class="tab2-p2">帮助学校打造一套结合学校实际情况出发的综合能力育人课程，建设多元化办学格局。</p>
                            </div>
                        </li>
                        <!-- <li @click="cur3=2" :class="{active:cur3==2}">
                            <img :src="k1" alt="" class="schoolBigDataIco2" @mouseover="k1 = kc11,change3(2)" @mouseout="k1 = kc1">
                            <span>课程画像</span>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="jsc-bg">
            <div class="schoolBigData-div5">
                <p class="administration-tit">管理驾驶舱</p>
                <p class="businessCenter-box1-text">
                    管理驾驶舱采用可视化交互式软件操作方式，区别于传统的表格式、文字式软件操作方式，让领导用一种更简单、快捷的方式，来运用信息化。
                </p>
                <el-carousel :interval="3000" type="card" height="420px" style="margin-top:50px;">
                    <el-carousel-item v-for="(item,i) in swiperList" :key="i">
                        <img :src="item.imgUrl" alt="" style="width:100%;height:100%;">
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            cur: 0,
            cur2: '00',
            cur3: 0,
            cur4: '00',
            p1: 'static/lvya/basicPlatform/schoolBigData/schoolBigDataIcon1.png',
            pic1: 'static/lvya/basicPlatform/schoolBigData/schoolBigDataIcon1.png',
            pic11: 'static/lvya/basicPlatform/schoolBigData/schoolBigDataIcon11.png',
            p2: 'static/lvya/basicPlatform/schoolBigData/schoolBigDataIcon2.png',
            pic2: 'static/lvya/basicPlatform/schoolBigData/schoolBigDataIcon2.png',
            pic22: 'static/lvya/basicPlatform/schoolBigData/schoolBigDataIcon22.png',
            p3: 'static/lvya/basicPlatform/schoolBigData/schoolBigDataIcon3.png',
            pic3: 'static/lvya/basicPlatform/schoolBigData/schoolBigDataIcon3.png',
            pic33: 'static/lvya/basicPlatform/schoolBigData/schoolBigDataIcon33.png',
            p4: 'static/lvya/basicPlatform/schoolBigData/schoolBigDataIcon4.png',
            pic4: 'static/lvya/basicPlatform/schoolBigData/schoolBigDataIcon4.png',
            pic44: 'static/lvya/basicPlatform/schoolBigData/schoolBigDataIcon44.png',
            x1: 'static/lvya/basicPlatform/schoolBigData/xshx1.png',
            xs1: 'static/lvya/basicPlatform/schoolBigData/xshx1.png',
            xs11: 'static/lvya/basicPlatform/schoolBigData/xshx11.png',
            j1: 'static/lvya/basicPlatform/schoolBigData/jshx1.png',
            js1: 'static/lvya/basicPlatform/schoolBigData/jshx1.png',
            js11: 'static/lvya/basicPlatform/schoolBigData/jshx11.png',
            k1: 'static/lvya/basicPlatform/schoolBigData/kchx1.png',
            kc1: 'static/lvya/basicPlatform/schoolBigData/kchx1.png',
            kc11: 'static/lvya/basicPlatform/schoolBigData/kchx11.png',
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选择的播放速度
                autoplay: true, // 如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: 'video/mp4',
                    src: 'http://zhanhui.lvya.org/file/jinling.mp4'// url地址
                }],
                poster: 'static/lvya/basicPlatform/schoolBigData/bgg.png', // 你的封面地址
                // width: document.documentElement.clientWidth,
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true, // 全屏按钮
                }
            },
            swiperList: [
                {
                    imgUrl: require('../../../../public/static/lvya/basicPlatform/schoolBigData/jsc1.png'),
                }, {
                    imgUrl: require('../../../../public/static/lvya/basicPlatform/schoolBigData/jsc2.png'),
                }, {
                    imgUrl: require('../../../../public/static/lvya/basicPlatform/schoolBigData/jsc3.png'),
                },
            ]
        }
    },
    methods: {
        change: function(index) {
            this.cur = index
        },
        change2: function(index) {
            this.cur2 = index
        },
        change3: function(index) {
            this.cur3 = index
        },
        change4: function(index) {
            this.cur4 = index
        },
    },
    created() {
    },
    mounted() {
    },
}
</script>

<style lang="less" scoped>
.lvya-schoolBigData{
  min-width: 1200px;
}
.schoolBigData-div1{
    width: 100%;
    min-width: 1200px;
    position: relative;
    display: flex;
    justify-content: center;
    img{
      max-width: 100%;
    }
}
.schoolBigData-ul{
    width: 900px;
    position: absolute;
    bottom: -105px;
    display: flex;
    justify-content: space-between;
}
.schoolBigData-ul li{
    width: 30%;
    text-align: center;
    background: #fff;
    padding: 30px 20px;
    box-sizing: border-box;
    -moz-box-shadow:1px 2px 9px #555; -webkit-box-shadow:1px 2px 9px #555; box-shadow:1px 2px 9px #ccc;
    border-radius: 5px;
}
.schoolBigData-ul-p1{
    color: #333;
    font-weight: 600;
    padding: 10px 0;
}
.schoolBigData-ul-p2{
    color: #666;
    font-size: 14px;
}
.schoolBigData-div2{
    width: 1200px;
    height: 300px;
    margin: 150px auto;
    margin-top: 200px;
    display: flex;
}
.schoolBigData-div2 .tab-tilte{
    width: 400px;
    min-height: 200px;
    margin-left: 200px;
}
.schoolBigData-div2 .tab-content{
    margin-left: 50px;
    min-height: 200px;
}
.schoolBigData-div2 .tab-content div img{
    width: 75%;
}
.schoolBigData-div2 .tab-tilte li{
    width: 100%;
    text-align: left;
    cursor: pointer;
    color: #19ca81;
    padding: 10px 0;
    margin: 30px 0;
    display: flex;
}
.schoolBigData-div2 .tab-tilte li span{
    display: inline-block;
}
/* 点击对应的标题添加对应的背景颜色 */
.schoolBigData-div2 .tab-tilte .active{
    background: #19ca81;
    color: #fff!important;
}
.schoolBigDataIco{
    width: 20px;
    height: 20px;
    margin: 0 10px;
}
.schoolBigData-div3-bg{
    width: 100%;
    background: red;
    padding: 100px 0;
    background: url(../../../../public/static/lvya/basicPlatform/schoolBigData/videoBg.png) no-repeat scroll center top;
    background-size: 100% 100%;
}
.schoolBigData-div3{
    width: 1000px;
    min-height: 300px;
    margin: 0px auto;
}
.schoolBigData-div3 video{
    width: 100%;
}
.schoolBigData-bg{
    width: 100%;
    background: url('../../../../public/static/lvya/basicPlatform/schoolBigData/xysysbg.png') no-repeat scroll center top;
    background-size: 100% 100%;
    padding: 30px 0;
}
.schoolBigData-div4{
    width: 1200px;
    min-height: 300px;
    margin: 50px auto;
}
.administration-tit{
    text-align: center;
    font-size: 24px;
    color: #333;
    font-weight: 600;
}
.businessCenter-box1-text{
    margin-top: 20px;
    text-align: center;
    line-height: 30px;
    color: #666;
}
.tab2{
    width: 100%;
    min-height: 300px;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
}
.tab2 .tab-tilte{
    width: 550px;
    min-height: 200px;
}
.tab2 .tab-content{
    margin-right: 50px;
    min-height: 200px;
    margin-top: 100px;
}
.tab2 .tab-tilte li{
    width: 100%;
    text-align: left;
    cursor: pointer;
    color: #19ca81;
    padding: 10px 0;
    margin: 10px 0;
    display: flex;
}
.tab2-p1{
    padding: 10px 0;
}
.tab2-p2{
    color: #666;
    font-size: 14px;
}
.jsc-bg{
    width: 100%;
    background: url('../../../../public/static/lvya/basicPlatform/schoolBigData/jscbg.png') no-repeat;
    background-size: 100% 100%;
}
.schoolBigData-div5{
    width: 1200px;
    min-height: 300px;
    margin: 0 auto 50px auto;
    padding: 50px 0;
}
/* 点击对应的标题添加对应的背景颜色 */
.schoolBigDataIco2{
    width: 70px;
    height: 70px;
    margin: 0 10px;
}
</style>
